@import url('../../styles/customMediaQueries.css');

/* Container for side and content */

.filters {
  composes: row from global;
}

.layoutWrapperFilterColumn {
  display: none;

  @media (width >= 768px) {
    transform: translateX(0);

    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    transition: transform 2s;
  }
}

.filters .layoutWrapperFilterColumn {
  transform: translateX(-100);
}

.filterColumnContent {
  width: 100%;
  padding: var(--n-size-1) var(--n-size-3) 0 0;
}

.filter:first-child {
  padding-top: 0;
}

.layoutWrapperMain {
  display: grid;
  align-content: start;
  width: 100%;

  @media (width >= 768px) {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
  }
}

.searchHeaderDesktop {
  @media (width >= 768px) {
    grid-column: 1 / span 2;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.newSearchInProgress {
  opacity: 0.1;
}

.sidebar {
  overflow: hidden;

  /* Framer Motion sets display to "block" using style attribute,
     so we can't fix this with specificity */
  display: none !important;
  flex-direction: column;
  justify-content: flex-start;

  background-color: white;

  &[data-open='true'] {
    border-right: 1px solid var(--n-color-border-active);
  }

  @media (width >= 768px) {
    display: flex !important;
  }
}

.sidebarContent {
  padding: var(--n-size-2);

  @media (width >= 1024px) {
    padding: var(--n-size-3);
  }
}

/* Listing */

.listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--n-size-2);
  transition: 1.5ms ease;

  @media (width >= 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (width >= 1024px) {
    gap: var(--n-size-3);
  }

  @media (width >= 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingsContent {
  display: grid;
  gap: var(--n-size-2);
  align-content: start;
  padding: 0 16px var(--n-size-2);

  @media (width >= 1024px) {
    padding: 0 24px var(--n-size-3);
  }
}

.filterChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--n-size-1);
}

.clearAllFiltersButton {
  cursor: pointer;
  background: var(--n-color-white);
  border: 1px solid var(--n-color-text-subtle);

  &:hover {
    background: var(--n-color-white);
  }
}
