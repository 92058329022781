.root {
  touch-action: none;
  user-select: none;

  position: relative;

  display: flex;
  align-items: center;

  height: 20px;
}

.track {
  position: relative;

  flex-grow: 1;

  height: 2px;

  background: var(--n-color-border);
  border-radius: var(--radius-full);
}

.range {
  position: absolute;
  height: 100%;
  background: var(--n-color-text-subtle);
  border-radius: var(--radius-full);
}

.thumb {
  display: block;

  width: 14px;
  height: 14px;

  background-color: var(--n-color-text-dark);
  border-radius: var(--radius-full);

  &:focus {
    outline: none;
  }
}
