@import url('../../../styles/customMediaQueries.css');

.root {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-2);
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  padding: var(--n-size-2);

  & .categoryOptions {
    grid-column: 1 / span 2;
  }

  @media (width >= 1024px) {
    grid-template-columns: 1fr 2fr 1fr;
    gap: var(--n-size-3);
    padding: var(--n-size-3);

    & .categoryOptions {
      grid-column: initial;
    }

    & .filterButton {
      order: -1;
    }
  }
}

.categoryOptions {
  overflow: auto;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  gap: var(--n-size-2);
  align-items: center;

  margin: 0 calc(-1 * var(--n-size-2));
  padding: 0 var(--n-size-2);

  @media (width >= 768px) {
    overflow: initial;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
}

.link {
  display: grid;
  place-items: center;

  height: 48px;
  padding: 0 var(--n-size-1);

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
  white-space: nowrap;

  border-bottom: 2px solid transparent;

  transition: border 0.15s;

  &:hover {
    text-decoration: none;
    border-bottom: 2px solid var(--n-color-border);
  }

  &[data-active='true'] {
    border-bottom-color: var(--n-color-text-dark);
  }
}

.filterButton {
  width: fit-content;
  padding: 8px 8px var(--n-size-1) 0;

  color: var(--n-color-text-dark);

  background-color: white;
  border-color: transparent;

  &:hover {
    @media (width >= 768px) {
      background: var(--background-light-seconday, #f5f7fa);
    }
  }

  &[data-open='true'] {
    color: var(--n-color-white);

    &:hover {
      @media (width >= 768px) {
        background: var(--n-color-text-dark);
      }
    }

    & > svg {
      fill: white !important;
    }

    & > svg > path {
      fill: white !important;
    }
  }

  @media (width >= 768px) {
    padding: var(--n-size-1) var(--n-size-3);
    border-color: var(--n-color-text-dark);

    &[data-open='true'] {
      background-color: black;
      border-color: transparent;
    }
  }
}

.sortBy {
  display: inline-flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-self: end;

  width: fit-content;

  & > span {
    display: block;
    white-space: nowrap;
  }

  & > select {
    border-bottom: none;
  }
}
