.label {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
}

.colorCircle {
  display: inline-block;

  width: 12px;
  height: 12px;

  background: var(--n-color-white);
  border-radius: 50%;
}

.chip[data-active='true'] .colorCircle {
  outline: 1px solid white;
}
