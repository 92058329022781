.chipChildrenWrapper {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
}

.chip {
  display: inline-flex;
  gap: var(--n-size-2);
  align-items: center;

  padding: var(--n-size-1) var(--n-size-2);

  /* Body/Body 2/Regular */
  font-family: Montserrat;
  font-size: var(--n-font-size-00);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-black);

  background-color: #f5f7fa;
  border-radius: var(--radius-full);

  transition: background-color 0.15s ease;

  &:hover {
    background-color: var(--n-color-border);
  }

  &[data-active='true'] {
    color: white;
    background: var(--n-color-black);

    &:hover {
      background-color: var(--n-color-text-dark);
    }
  }
}

.chipCloseButton {
  cursor: pointer;

  display: grid;
  place-content: center;

  margin: 0;
  padding: 0;

  border: 0;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: -2px;
  }
}
