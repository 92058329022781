.root {
  display: grid;
  gap: var(--n-size-3);
  justify-items: center;

  max-width: 480px;
  margin: 0 auto;
  padding: var(--n-size-9) 0;

  & > h1,
  & > p {
    margin: 0;
    padding: 0;
  }

  & h1 {
    font-family: var(--fontFamilySyne);
    font-size: var(--n-font-size-3);
    font-weight: 700;
    color: var(--n-color-text-dark);
    text-align: center;
    text-transform: uppercase;
  }

  & p {
    font-family: Montserrat;
    font-size: var(--n-font-size-0);
    font-weight: 400;
    color: var(--n-color-text-dark);
    text-align: center;
  }
}
